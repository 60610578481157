import React from 'react'
import Header from './Header'
// import ViewInArIcon from '@mui/icons-material/ViewInAr';
import {AppBar, styled} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Img from "../assets/code_logo.png"
import Code from "../components/Code" 
import Result from './Result';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';



const Container = styled(AppBar)`
        background: #F0A500;
        height: 8vh;
`

const Home = () => {
  return (
    <div>

        <Container position='static'>
            <Toolbar>
              
            {/* <img src={Img} alt='logo' className='header_img' /> */}
        <Header />
            </Toolbar>
        </Container>

        <Code />
        <Result />

    </div>
    
  )
}

export default Home