import React, { useState } from 'react'
import {Box, styled} from '@mui/material';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
// import CodeMirror from 'react-codemirror2'
import {Controlled as ControlledEditor} from 'react-codemirror2';


import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';


const Heading = styled(Box)`
        background: #1d1e22;
        display:flex;
        padding:9px 9px
`
const Section = styled(Box)`
        flex-grow:1;
        display:flex;
        flex-direction:column;
        flex-basis:0;
        padding:0 6px 6px;
   
`

const Editor = (props) => {

    const [toggle,setToggle] = useState(true)

    const handleChange = (editor,data,value) =>{
        props.onChange(value)
    }
  return (
   <Section style={toggle ? null : {flexGrow: 0}}>
    <Box style={{
        display:'flex',
        background:'#060606',
        color:'#AAAEBC',
        justifyContent:'space-between',
        fontWeight:700
        
    }}>
        <Heading >
            <Box component='span'
            style={{
                background: props.color,
                height:20,
                width:20,
                display:'flex',
                placeContent:"center",
                borderRadius:5,
                marginRight:5,
                paddingBottom:2,
                color:'#333'
            }}
            >{props.icon}
            </Box>
            {props.heading}
        </Heading>
        <CloseFullscreenIcon 
        fontSize='small'
        style={{alignSelf:"center"}}
        onClick = {()=>{
            setToggle(!toggle)
        }}/>
    </Box>
    <ControlledEditor 
    className='controlled-editor'
    value={props.value}
    onBeforeChange={handleChange}
    options={{
        theme:'material',
        lineNumbers:true
    }}/>
   </Section>

  )
}

export default Editor