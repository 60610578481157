import React, { useContext, useEffect, useState } from 'react'
import {Box,styled,iFrame} from "@mui/material"
import { DataContext } from '../context/DataProvider'

const Result = () => {

  const [src,setSrc] =  useState('')

  const {html,css,js} = useContext(DataContext)
  const scrCode = `

  <html>
  <body>${html}</body>
  <style>${css}</style>
  <script>${js}</script>
  </html>

  `

useEffect(() =>{
  const timeout = setTimeout(() =>{
      setSrc(scrCode)
  },1000)

  return () => clearTimeout(timeout);
}, [html,css,js])


  return (
    
    <Box style={{
      height:'41vh'
    }}>
        <iframe 
        srcDoc={src}
        title='Output'
        sandbox='allow-scripts'
        frameBorder={0}
        width='100%'
        height='100%'
        />
    </Box>
  )
}

export default Result