import React from 'react'
import HelpIcon from '@mui/icons-material/Help';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

const Header = () => {
  return (
    <>
      <div className="section_links">

      <div className="section_logo">

      <div className="header_logo">
      <DeveloperModeIcon 
        style={{
          fontSize:45
          
        }}/> 
      
        </div>
      
      </div>
      <h3>ProCode</h3>
      
      <div className="section_nav">
        
      <li>
        <a href=""><HelpIcon 
        style={{
          marginTop:50
        }}
        /><p>Help</p></a>
        
      </li>
      </div>
      
      </div>
    </>
  )
}

export default Header